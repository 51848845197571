import React, { useState, useEffect } from "react";
import $ from "jquery";
import Cart from '../commerce/Cart'
import shoppingBag from '../assets/shopping-bag.png'
import { useDispatch, useSelector } from 'react-redux';
import { selectTotalQTY, setOpenCart } from '../CartSlice.js';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [ifCartState, setIfCartState] = useState(false);
  const dispatch = useDispatch();
  const totalQTY = useSelector(selectTotalQTY);
  const [navState, setNavState] = useState(false);
  const [brands, setBrands] = useState([]);

  // mobile navigation
  const [isPortfolioOpen, setIsPortfolioOpen] = useState(false); // Mobile Portfolio dropdown state
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const onCartToggle = () => {
    setIfCartState(!ifCartState);
    dispatch(setOpenCart({
      cartState: true
    }))
  }

  const slugify = (text) => {
    return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '');
  }

  useEffect(() => {
    // Fetch the list of brands from the API
    const fetchBrands = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/brands'); // Adjust the URL
        // const response = await fetch('http://localhost:4000/brands');
        const data = await response.json();
        if (data.length > 1) {
          const temp = data[0];
          data[0] = data[1];
          data[1] = temp;
        }

        // Transform brand names for display
        // const transformedData = data.map((brand) => {
        //   if (brand.name.toLowerCase() === 'ad tear sheet') {
        //     return { ...brand, displayName: 'ad/tear sheet' };
        //   }
        //   return { ...brand, displayName: brand.name };
        // });

        setBrands(data)
        // setBrands(data);
      } catch (error) {
        console.error('Error fetching portfolio titles:', error);
      }
    };

    fetchBrands();
  }, []);


  // Custom easing function: easeInOutExpo
  function easeInOutExpo(t, b, c, d) {
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  }

  // Smooth scroll with custom easing
  useEffect(() => {
    $(function () {
      $("a.page-scroll").bind("click", function (event) {
        const $anchor = $(this);
        const target = $($anchor.attr("href")).offset().top;
        const duration = 1500;
        const start = $(window).scrollTop();
        const change = target - start;
        const increment = 20;
        let currentTime = 0;

        function animateScroll() {
          currentTime += increment;
          const val = easeInOutExpo(currentTime, start, change, duration);
          $(window).scrollTop(val);
          if (currentTime < duration) {
            setTimeout(animateScroll, increment);
          }
        }
        animateScroll();
        event.preventDefault();
      });
    });
  }, []);

  return (
    <>
      <Cart />
      <nav className="shadow-lg bg-[#252525]">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center xl:h-[100px] lg:h-[100px] h-[70px]">
            {/* Logo */}
            <div className="xl:text-2xl lg:text-xl md:text-xl text-2xl uppercase text-white font-semibold tracking-[0.1em]">
              <a href="/" style={{fontFamily: "Hanken Grotesk"}}>Sope Adelaja</a>
            </div>
            {/* Menu Items */}
            <nav className="hidden md:flex xl:space-x-12 space-x-8 items-center">
              <a href="/" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                Overview
              </a>
              <div className="relative group h-full flex items-center">
                <a
                  href="javascript:void(0)"
                  className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white"
                >
                  portfolio
                </a>
                <div
                  className="absolute hidden left-0 top-full group-hover:block text-white p-2 shadow-lg
                  overflow-hidden transition-all duration-500 ease-in-out bg-[#252525] z-50"
                >
                  {brands.map((brand) => (
                    <a
                      href={`/portfolio/${brand.name.replace(/\s+/g, '-').toLowerCase()}`}
                      key={brand._id}
                      className="capitalize block px-4 py-2 text-[12px] hover:bg-gray-800"
                    >
                      {/*{brand.name === 'ad tear sheet' ? 'ad/tear sheet' : brand.name}*/}
                      {brand.name}
                    </a>
                  ))}
                </div>
              </div>
              {/*<a href="/thoughts" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                thoughts
              </a>
              <a href="/prints" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                prints
              </a>*/}
              <div className="relative group h-full flex items-center">
                <a href="javascript:void(0)" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                  about
                </a>
                <div
                  className="absolute hidden left-0 top-full w-[150px] group-hover:block text-white p-2 shadow-lg
                  overflow-hidden transition-all duration-500 ease-in-out bg-[#252525] z-50">
                  <a href="/about/on-set" className="block px-4 py-2 text-[12px] hover:bg-gray-800 capitalize">on set</a>
                  <a href="/learn-more" className="block px-4 py-2 text-[12px] hover:bg-gray-800 capitalize">bio</a>
                  {/*<a href="/about/faq" className="block px-4 py-2 text-[12px] hover:bg-gray-800 uppercase">faq</a>*/}
                </div>
              </div>
              <a href="/contact" className="xl:text-[13px] lg:text-[11px] md:text-[11px] text-[13px] text-gray-600 hover:text-gray-400 uppercase tracking-[0.2em] page-scroll text-white">
                contact
              </a>
            </nav>



            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="text-white hover:text-white focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>


      <div className={`md:hidden overflow-hidden transition-all duration-500 ease-in-out bg-[#252525] ${isOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"}`}>
          <div className="flex flex-col items-center py-2 shadow-sm space-y-4">
            <a href="/" className="block px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsOpen(false)}>
              Overview
            </a>
            {/* Portfolio with Dropdown */}
            <div className="w-full">
              <button className="block w-full px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 hover:text-black uppercase tracking-[0.2em] page-scroll text-white" 
              onClick={() => setIsPortfolioOpen(!isPortfolioOpen)}>
                Portfolio
              </button>
              {isPortfolioOpen && (
                <div className="w-full px-4 space-y-2 text-center">
                  {brands.map((brand) => (
                    <a 
                      href={`/portfolio/${brand.name.replace(/\s+/g, '-').toLowerCase()}`}
                      key={brand._id} 
                      className="block px-4 py-2 text-[14px] text-white hover:bg-gray-800 capitalize">
                      {brand.name}
                    </a>
                  ))}
                </div>
              )}
            </div>
            {/* Thoughts */}
            {/*<a href="/thoughts" className="block px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsOpen(false)}>
              Thoughts
            </a>
            <a href="/prints" className="block px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsOpen(false)}>
              Prints
            </a>*/}
            {/* About with Dropdown */}
            <div className="w-full">
              <button className="block w-full px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 hover:text-black uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsAboutOpen(!isAboutOpen)}>
                About
              </button>
              {isAboutOpen && (
                <div className="w-full px-4 space-y-2 text-center">
                  <a href="/about/on-set" className="block px-4 py-2 text-[14px] text-white hover:bg-gray-800 capitalize">
                    On Set
                  </a>
                  <a href="/learn-more" className="block px-4 py-2 text-[14px] text-white hover:bg-gray-800 capitalize">
                    Bio
                  </a>
                  {/*<a href="/about/faq" className="block px-4 py-2 text-[14px] text-white :bg-gray-800 uppercase">
                    FAQ
                  </a>*/}
                </div>
              )}
            </div>
            {/* Contact */}
            <a href="/contact" className="block px-4 py-2 text-[12px] text-gray-600 hover:bg-gray-100 uppercase tracking-[0.2em] page-scroll text-white" onClick={() => setIsOpen(false)}>
              Contact
            </a>
          </div>
        </div>



    </nav>
    </>
  );
};

export default Navbar;