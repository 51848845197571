import React, { useState, useEffect } from 'react'
import Sidebar from './components/Sidebar'

const Academy = () => {
	const [tableData, setTableData] = useState([]);

	useEffect(() => {
		// Fetch data from the backend
		fetchData();
	}, []);

	// const URL = import.meta.env.VITE_BASE_URL;

	const fetchData = async () => {
	    try {
	      const response = await fetch('https:/sope-backend.vercel.app/academy'); // Replace with your backend endpoint
	      if (response.ok) {
	        const data = await response.json();
	        setTableData(data);
	      } else {
	        console.error('Error fetching data');
	      }
	    } catch (error) {
	      console.error('Error fetching data:', error);
	    }
	};

	return (
		<>
			<div className="flex">
		      <Sidebar />
		      <div className="w-[85%]">
		        <div className="px-5 py-4 bg-black text-white">
		          <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
		        </div>
		        <div className="p-20">
		        	<div className="flex justify-center overflow-x-auto pl-[340px]">
					  <table className="border border-gray-300 pl-80">
					    <thead>
					      <tr className="bg-gray-100">
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Email</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">phone no.</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">location</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">gender</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">training</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">photo focus</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">payment</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">health condition</th>
					        <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Message</th>
					      </tr>
					    </thead>
					    <tbody className="bg-white divide-y divide-gray-300">
					      {/* Table rows */}
					      {tableData.length > 0 && tableData.map(academy => (
						    <tr className="hover:bg-gray-100" key={academy._id}>
						        <td className="px-6 py-4 whitespace-nowrap capitalize" style={{fontFamily: "Muli"}}>{academy.fullName}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.email}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.phoneNumber}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.location}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.gender}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.photoTraining}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.photographyFocus}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.sessionPayment}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.healthCondition}</td>
						        <td className="px-6 py-4 whitespace-nowrap capitalize"style={{fontFamily: "Muli"}}>{academy.message}</td>
						    </tr>
					      ))}
					    </tbody>
					  </table>
					</div>

		        </div>
		      </div>
		    </div>
		</>
	)
}

export default Academy