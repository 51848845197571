import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

// Initial state with localStorage loading
const initialState = {
  cartState: false,
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [], // Load cart from localStorage
  cartTotalAmount: 0,
  cartTotalQantity: localStorage.getItem("totalQTY")
    ? JSON.parse(localStorage.getItem("totalQTY"))
    : 0, // Load total quantity from localStorage
};

const CartSlice = createSlice({
  initialState,
  name: "cart",
  reducers: {
    setOpenCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },
    setCloseCart: (state, action) => {
      state.cartState = action.payload.cartState;
    },

    setAddItemToCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        // Update quantity if the item already exists in the cart
        state.cartItems[itemIndex].cartQuantity += action.payload.cartQuantity;
      } else {
        // Add new item to the cart
        const tempProduct = { ...action.payload, cartQuantity: action.payload.cartQuantity || 1 };
        state.cartItems.push(tempProduct);
      }
      state.totalQTY += action.payload.cartQuantity || 1;

      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setIncreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        state.cartItems[itemIndex].cartQuantity += 1;
        state.totalQTY += 1;
      }
      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setDecreaseItemQTY: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0 && state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;
        state.totalQTY -= 1;
      } else if (itemIndex >= 0 && state.cartItems[itemIndex].cartQuantity === 1) {
        // If quantity is 1 and you decrease it, remove the item
        state.cartItems.splice(itemIndex, 1);
        state.totalQTY -= 1;
      }
      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setRemoveItemFromCart: (state, action) => {
      const itemIndex = state.cartItems.findIndex(item => item.id === action.payload.id);
      if (itemIndex >= 0) {
        state.totalQTY -= state.cartItems[itemIndex].cartQuantity; // Subtract the removed quantity from total
        state.cartItems.splice(itemIndex, 1);
      }
      // Save to localStorage
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
      localStorage.setItem("totalQTY", JSON.stringify(state.totalQTY));
    },

    setClearCartItems: (state) => {
      state.cartItems = [];
      state.totalQTY = 0;

      // Clear from localStorage
      localStorage.removeItem("cartItems");
      localStorage.removeItem("totalQTY");
    },

    setGetTotals: (state, action) => {
      let { totalAmount, totalQTY } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { price, cartQuantity } = cartItem;
          const totalPrice = price * cartQuantity;

          cartTotal.totalAmount += totalPrice;
          cartTotal.totalQTY += cartQuantity;

          return cartTotal;
        },
        {
          totalAmount: 0,
          totalQTY: 0,
        }
      );

      state.cartTotalAmount = totalAmount;
      state.cartTotalQantity = totalQTY;

      // Save totals to localStorage (optional if you want to persist total amount as well)
      localStorage.setItem("cartTotalAmount", JSON.stringify(state.cartTotalAmount));
      localStorage.setItem("totalQTY", JSON.stringify(state.cartTotalQantity));
    },
  },
});

export const {
  setOpenCart,
  setCloseCart,
  setAddItemToCart,
  setRemoveItemFromCart,
  setIncreaseItemQTY,
  setDecreaseItemQTY,
  setClearCartItems,
  setGetTotals,
} = CartSlice.actions;

export const selectCartState = (state) => state.cart.cartState;
export const selectCartItems = (state) => state.cart.cartItems;
export const selectTotalAmount = (state) => state.cart.cartTotalAmount;
export const selectTotalQTY = (state) => state.cart.cartTotalQantity;

export default CartSlice.reducer;
