import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { setExchangeRates } from "./commerce/cart/CartSlice";
// import Navbar from './components/Navbar';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/Home';
import Contact from './pages/Contact';
import Bio from './pages/about/Bio';
import Faq from './pages/about/Faq';
import Blog from './pages/Blog';
import Portfolio from './pages/Portfolio';
import FrontOnset from './pages/Onset';
import Preloader from './components/Pre'; // Import the Preloader component
import Shop from "./commerce/Shop";
import ProductDetail from "./pages/ProductDetail";
import ProductPage from "./commerce/ProductPage";

import Adminblog from './admin/Blog';
import PostBlog from './admin/PostBlog';
import EditBlog from './admin/EditBlog';
import Register from './admin/Register';
import AdminContact from './admin/Contact';
import AdminAcademy from './admin/Academy';
import Subscribers from './admin/Subscribers';
import Brand from './admin/BrandForm';
import EditBrand from './admin/EditBrandForm';
import SubBrand from './admin/SubBrandForm';
import ImageUploadForm from './admin/ImageUploadForm';
import UploadProduct from './admin/UploadProduct';
import SubbrandImageUpload from './admin/SubbrandImageUpload';
import AdminPortfolio from './admin/Portfolio';
// import Login from './admin/Login';
import Home from './admin/Index';
import CreatePost from './admin/CreatePost';
import Orders from './admin/Orders';
import Onset from './admin/Onset';

// This component helps in conditionally rendering Navbar based on route
function Layout() {

  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {/* Render Navbar only if it's not an admin route */}
      {!isAdminRoute && <Navbar />}

      {/*<Navbar />*/}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/learn-more" element={<Bio />} />
        <Route path="/about/faq" element={<Faq />} />
        <Route path="/thoughts" element={<Blog />} />
        {/*<Route path="/portfolio/:brandName" element={<Portfolio />} />*/}
        <Route path="/portfolio/:brandName" element={<Portfolio />} />
        <Route path="/about/on-set" element={<FrontOnset />} />

        <Route path="/prints" element={<Shop />} />
        <Route path="/product-page/:id" element={<ProductDetail />} />
        <Route path="/product/:productId" element={ProductPage} />

        <Route path="/admin/blog" element={<Adminblog />} />
        <Route path="/admin/blog/:id" element={<PostBlog />} />
        <Route path="/admin/edit/:id" element={<EditBlog />} />
        <Route path="/admin/register" element={<Register />} />
        <Route path="/admin/contact" element={<AdminContact />} />
        <Route path="/admin/academy" element={<AdminAcademy />} />
        <Route path="/admin/subscribers" element={<Subscribers />} />
        <Route path="/admin/portfolio-title" element={<Brand />} />
        <Route path="/admin/editbrand" element={<EditBrand />} />
        <Route path="/admin/upload-product" element={<UploadProduct />} />
        <Route path="/admin/subbrand" element={<SubBrand />} />
        <Route path="/admin/upload-to-portfolio" element={<ImageUploadForm />} />
        <Route path="/admin/upload-to-subportfolio" element={<SubbrandImageUpload />} />
        <Route path="/admin/portfolio/:id" element={<AdminPortfolio />} />
        {/*<Route path="/admin/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />*/}
        <Route path="/admin/home" element={<Home />} />
        <Route path="/admin/create" element={<CreatePost />} />
        <Route path="/admin/orders" element={<Orders />} />
        <Route path="/admin/on-set" element={<Onset />} />
      </Routes>
      <Footer />
    </>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a network request or loading process
    const timer = setTimeout(() => setLoading(false), 3000); // Adjust duration as needed
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {loading ? (
        <Preloader /> // Show preloader while loading
      ) : (
        <BrowserRouter>
          <Layout /> {/* Render layout with conditional Navbar */}
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
