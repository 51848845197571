import React from 'react';
import {Helmet} from "react-helmet";

import image from "../../assets/ourworks2.jpg"

const Faq = () => {
	return (
		<>
			<Helmet>
		        <title>Sope Adelaja | Photographer - Film Director</title>
		        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
		    </Helmet>
			<div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] 
				xl:px-20 xl:py-4 px-4 py-4 bg-[#252525]">
				<img className="object-cover h-[100%] w-[100%]" src={image} alt="cover" />
			</div>

			
			<div className="xl:px-[15em] lg:px-10 md:px-10 px-4 py-10 bg-[#252525]">
				<div>
					<div className="border-b border-[#b7a78b] py-4 mb-6">
						<p className="uppercase text-2xl text-[#b7a78b] font-semibold tracking-[.15em]" 
							style={{fontFamily: "Hanken Grotesk"}}>faqs</p>
					</div>
					
					<div className="my-2">
						<p className="mb-2 text-white text-[15px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et 
						dolore magna aliqua?</p>
						<p className="text-[#b7a78b] text-[15px]">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, 
						eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam 
						voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem 
						sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non 
						numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis 
						nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui 
						in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
					</div>

					<div className="my-2">
						<p className="mb-2 text-white text-[15px]">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et 
						dolore magna aliqua?</p>
						<p className="text-[#b7a78b] text-[15px]">consectetur, adipisci velit, sed quia non 
						numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis 
						nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis a</p>
					</div>

					<div className="my-2">
						<p className="mb-2 text-white text-[15px]">Magnam aliquam quaerat voluptatem. Ut enim ad minima veniam?</p>
						<p className="text-[#b7a78b] text-[15px]">ad minima veniam, quis 
						nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui 
						in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
					</div>

					<div className="my-2">
						<p className="mb-2 text-white text-[15px]">Labore et dolore magnam aliquam quaerat voluptatem?</p>
						<p className="text-[#b7a78b] text-[15px]">Quis autem vel eum iure reprehenderit qui 
						in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur</p>
					</div>
				</div>

				
			</div>

		</>
	)
}

export default Faq