import React from 'react'
import { useNavigate } from 'react-router-dom';
// import emptybag from '../../assets/emptybag.png'
import { ArrowLeftIcon } from '@heroicons/react/24/outline'

const CartEmpty = () => {
	const navigate = useNavigate();

    const handleBackToShop = () => {
        navigate('/shop');
    };
	return (
		<>
			<div className="flex items-center justify-center flex-col h-screen px-11 text-center gap-7">
				{/*<img 
					src={emptybag}
					alt="emptybag/img"
					className="w-40 lg:w-36 sm:w-28 h-auto object-fill transition-all duration-300 hover:scale-110"
				/>*/}
				<p className="text-base text-[#373333]">Your cart is empty!</p>
				{/*<button type="button" className="button-theme bg-gradiant-to-b 
					from-amber-500 to-orange-500 shadow-lg sharow-orange-500 flex items-center 
					justify-center text-slate-900 py-2 gap-3 text-sm px-5 font-semibold active:scale-110"
					onClick={handleBackToShop}
				>
					<ArrowLeftIcon className="w-5 h-5 text-white" />
					<span className="text-white">Back To Shop</span>
				</button>*/}
			</div>
		</>
	)
}

export default CartEmpty