import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'

const Onset = () => {
  
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);
	const [images, setImages] = useState([]);
	const [brandName, setBrandName] = useState('')
	const {brandName: urlBrandName} = useParams()

	const [isOpen, setIsOpen] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(0);


  // const URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchImages();
  }, []);
  
  const fetchImages = async () => {
    try {
      // const response = await fetch('http://localhost:4000/onset');
      const response = await fetch('https://sope-backend.vercel.app/onset/');
      if (response.ok) {
        const data = await response.json();
        setImages(
          data.map((image) => ({
            src: image.image, // Use 'src' instead of 'original' for react-photo-gallery
            alt: image.alt,
          }))
        );
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  

  // Open the lightbox
  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  // Close the lightbox
  const closeLightbox = () => {
    setIsOpen(false);
  };

  // Go to the next image
  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  // Go to the previous image
  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };




  return (
    <div className="px-auto p-4 bg-[#252525] w-full min-h-screen">
    	
	   	<div className="xl:pt-[20px] lg:pt-[20px] md:pt-[20px] sm:pt-[30px] pt-[10px]">
					
				<div className="">

					<div className="xl:px-[250px] lg:px-20 md:px-20 px-10 mb-10">
						<div className="grid xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
							{images.map((image, index) => (
								// <div key={index}>
				        	<img
                    key={index}
				    	      src={image.src}
							    	alt={image.alt}
							      className="w-full h-auto cursor-pointer"
								  />
								// </div>
					   	))}
						</div>
					</div>

				</div>
			</div>
    </div>
  );
};

export default Onset;
