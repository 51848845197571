import React, {useState} from 'react';
import Modal from 'react-modal';
import { PaystackButton } from 'react-paystack'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: "5%",
    transform: 'translate(-50%, -50%)',
    height: '70vh',
    // width: '40vw',
    width: '80%',
    maxWidth: '500px',
  },
  overlay: {zIndex: 1000}
};

// Set the app element to the root element with ID "root"
Modal.setAppElement('#root');

const CheckoutModal = ({ isOpen, closeModal, totalAmount, onClearCartItems }) => {
  const [formData, setFormData] = useState({
      name: '',
      address: '',
      email: '',
      phone: '',
      country: '',
  });

  const publicKey = "pk_test_75e54c2203668d7d94608108b300d30cab8fb3e0"

  const paystackAmount = totalAmount * 100
  const totalAmountWithShipping = 5000 * 100;
 
  const paystackTotal = formData.country === "Nigeria" ? paystackAmount : paystackAmount + totalAmountWithShipping;

  const contactForm = async (e) => {
    e.preventDefault();
    // const shippingFee = formData.country === "Nigeria" ? 0 : 5000; // Set shipping fee based on country
    // const totalAmountWithShipping = totalAmount + shippingFee; // Calculate total amount including shipping fee


    localStorage.setItem('formData', JSON.stringify(formData));  

    closeModal();  
  }

  const handlePaystackSuccess = async (reference) => {
    try {
      // Retrieve form data from local storage
      const formDataFromLocalStorage = JSON.parse(localStorage.getItem('formData'));
      // const cartItem = JSON.parse(localStorage.getItem('cart'));
      const cartItems = JSON.parse(localStorage.getItem('cartItems'))
      
      // Post form data to backend
      const response = await fetch('http://localhost:4000/success', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formDataFromLocalStorage,
          reference: reference,
          cartItem: cartItems,
        }),
      });

      if (response.ok) {
        alert('Your order has been placed!');
        // Reset the form data after successful submission
        // setFormData({
        //   name: '',
        //   address: '',
        //   email: '',
        //   phone: '',
        //   country: '',
        // });
      } else {
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      // Clear form data from local storage
      localStorage.removeItem('formData');
    }
  };

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
  };


    const handlePaystackCloseAction = () => {
      alert("Wait! Don't leave ")
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }

    const componentProps = {
      reference: (new Date()).getTime().toString(),
      email: formData.email,
      amount: (paystackTotal),
      metadata: {
        name: formData.name,
        phone: formData.phone,
      },
      publicKey,
      text: "Pay Now",
      onSuccess: (reference) => {
        handlePaystackSuccess(reference);
        onClearCartItems();
      },
      onClose: handlePaystackCloseAction,
    }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Checkout Modal"
    >
      <div className="flex justify-end">
        <button onClick={closeModal}>
          <img width="40" height="40" src="https://img.icons8.com/ios/40/delete-sign--v1.png" alt="delete-sign--v1"/>
        </button>
      </div>

      <h2 className="uppercase mb-5 text-3xl font-semibold">checkout</h2>
      
      <form onSubmit={contactForm}>
        <div className="grid xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 grid-cols-1">
          <div className="textCenter">
            <input  
              type="text" 
              name="name"
              className="border border-[#dfd6d6] mb-5 w-[100%] px-6 py-4"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Full Name" 
              style={{fontFamily: "Muli"}}
              required
            />
          </div>
          <div>
            <input 
              type="text"
              name="email"
              className="border border-[#dfd6d6] mb-5 w-[100%] px-6 py-4"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="E-mail"
              style={{fontFamily: "Muli"}}
              pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
              required
            />
            {formData.email.length > 0 && !formData.email.match("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}") && (
              <span className="text-red-500 text-sm">Invalid email format</span>
            )}
          </div>
          <div>
            <input
              id="message"
              rows="4"
              placeholder="Phone Number"
              name="phone"
              className="border border-[#dfd6d6] mb-5 w-[100%] px-6 py-4"
              value={formData.phone}
              onChange={handleInputChange}
              style={{fontFamily: "Muli"}}
              required
            />
          </div>
          <div>
            <input
              type="text" 
              name="address"
              className="border border-[#dfd6d6] mb-5 w-[100%] px-6 py-4"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Address" 
              style={{fontFamily: "Muli"}}
              required
            />
          </div>
          <div>
            <select
              name="country"
              className="border border-[#dfd6d6] mb-5 w-[100%] px-6 py-4"
              value={formData.country}
              onChange={handleInputChange}
              style={{fontFamily: "Muli"}}
              required
            >
              <option value="">Select Country</option>
              <option value="USA">United States</option>
              <option value="UK">United Kingdom</option>
              <option value="Nigeria">Nigeria</option>
              {/* Add more options for other countries */}
            </select>
          </div>

        </div>

        {/*<div className="">
          <button className="bg-black px-20 py-3 tracking-[.25em] text-sm text-white uppercase">send</button>
        </div>*/}
          <PaystackButton className=" bg-black text-white px-8 py-4 hover:bg-[#4f4a4a] transition" {...componentProps} />
      </form>
    </Modal>
  );
};

export default CheckoutModal;
