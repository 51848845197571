import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from './components/Sidebar';

const ImageUploadForm = () => {
  const [brands, setBrands] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch('http://localhost:4000/brands');
        const data = await response.json();
        setBrands(data);
      } catch (error) {
        console.error('Error fetching brands:', error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    fetchUploadedImages();
  }, []);

  const fetchUploadedImages = async () => {
    try {
      const response = await fetch('http://localhost:4000/upload');
      if (response.ok) {
        const data = await response.json();
        setUploadedImages(data);
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleBrandSelect = (event) => {
    setSelectedBrandId(event.target.value);
  };

  const handleImageChange = (event) => {
    setImages(event.target.files);
  };

  const handleUpload = async () => {
    if (!selectedBrandId) {
      alert('Please select a portfolio');
      return;
    }

    if (images.length === 0) {
      alert('Please select at least one image to upload');
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      Array.from(images).forEach((image) => {
        formData.append('images', image); // 'images' matches the backend's expected field name
      });

      const response = await fetch(`http://localhost:4000/upload/${selectedBrandId}`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Images uploaded successfully');
        fetchUploadedImages(); // Refresh the uploaded images
      } else {
        console.error('Error uploading images:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePortfolio = async (portfolioId) => {
    try {
      const response = await fetch(`http://localhost:4000/upload/${portfolioId}`, { 
        method: 'DELETE' 
      });

      if (response.ok) {
        setUploadedImages(uploadedImages.filter((image) => image.id !== portfolioId));
        alert('Image has been deleted successfully');
      } else {
        const errorData = await response.json();
        console.error('Error deleting Image:', errorData);
      }
    } catch (error) {
      console.error('Error deleting Image:', error);
    }
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-full">
          <div className="px-2 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{ fontFamily: "Muli" }}>welcome, admin</p>
          </div>
          <div className="p-2">
            <h2 className="text-4xl font-semibold mb-4 capitalize">upload images to portfolio</h2>
            <select 
              className="mr-2 cursor-pointer" 
              style={{ fontFamily: "Muli" }} 
              value={selectedBrandId} 
              onChange={handleBrandSelect}
            >
              <option value="" style={{ fontFamily: "Muli" }}>Select a portfolio</option>
              {brands.map((brand) => (
                <option key={brand._id} value={brand._id} style={{ fontFamily: "Muli" }}>
                  {brand.name}
                </option>
              ))}
            </select>
            <input 
              style={{ fontFamily: "Muli" }} 
              type="file" 
              onChange={handleImageChange} 
              className="cursor-pointer" 
              multiple // Allow multiple file selection
            />
            <button 
              className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase" 
              style={{ fontFamily: "Muli" }} 
              onClick={handleUpload}
            >
              {loading ? 'Uploading Images...' : 'Upload Images'}
            </button>
          </div>
          <div className="p-2">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Images</h2>
            <div>
              <table className="min-w-full border border-gray-300 mt-10">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>image</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>delete portfolio</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {uploadedImages.map((image, index) => (
                    <tr className="hover:bg-gray-100" key={index}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ fontFamily: "Muli" }}>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap capitalize" style={{ fontFamily: "Muli" }}>
                        <Link to={`/admin/portfolio/${image.brand}`} className="underline text-blue-500">
                          {image.brandName}
                        </Link>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img src={image.image} alt="" className="w-1/5" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button 
                          style={{ fontFamily: "Muli" }}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                          onClick={() => handleDeletePortfolio(image.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUploadForm;
