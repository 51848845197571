import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Sidebar from './components/Sidebar'

const Onset = () => {
  // const [image, setImage] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);

  // const URL = import.meta.env.VITE_BASE_URL;


  

  useEffect(() => {
    fetchUploadedImages();
  }, []);

  const fetchUploadedImages = async () => {
    try {
      const response = await fetch('http://localhost:4000/onset');
      if (response.ok) {
        const data = await response.json();
        setUploadedImages(data);
      } else {
        console.error('Error fetching images');
      }
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const handleImageChange = (event) => {
    setImages(event.target.files);
  };

  const handleUpload = async () => {
    

    try {
      const formData = new FormData();
      // formData.append('images', image);
      Array.from(images).forEach((image) => {
        formData.append('images', image); // 'images' matches the backend's expected field name
      });

      const response = await fetch('http://localhost:4000/onset', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Image uploaded successfully');
        fetchUploadedImages();
        // Refresh the page after successful submission
        // window.location.reload();
      } else {
        console.error('Error uploading image:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDeleteOnset = async (onsetId) => {
    console.log('Deleting onset with ID:', onsetId);
    try {
      const response = await fetch(`http://localhost:4000/onset/${onsetId}`, { 
        method: 'DELETE' 
      });

      if (response.ok) {
        setUploadedImages(uploadedImages.filter((image) => image.id !== onsetId));
        alert('Image has been deleted successfully')
      } else {
        const errorData  = await response.json();
        console.error('Error deleting Image:', errorData);
      }
    } catch (error) {
      console.error('Error deleting Image:', error);
    }
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-full ">
          <div className="px-2 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
          </div>
          <div className="p-2">
            <h2 className="text-4xl font-semibold mb-4 capitalize">upload image to onset</h2>
            
            <input 
              style={{fontFamily: "Muli"}} 
              type="file" 
              onChange={handleImageChange} 
              className="cursor-pointer"
              multiple
            />
            <button 
              className="text-white text-base w-[200px] bg-black px-10 py-4 uppercase" 
              style={{fontFamily: "Muli"}} 
              onClick={handleUpload}>
              {loading ? 'Uploading Image...' : 'Upload Image'}
            </button>

            {loading && (
              <div className="mt-3 text-white"> {/* Loader display */}
                <p>Loading...</p>
              </div>
            )}

          </div>
          <div className="p-2">
            <h2 className="text-4xl font-semibold mb-4 capitalize">Uploaded Images to Onset</h2>
            <div >
              <table className="min-w-full border border-gray-300 mt-10">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>image</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete onset</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {uploadedImages.map((image, index) => (
                    <tr className="hover:bg-gray-100" key={index}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap"><img src={image.image} alt="" className="w-1/5" /></td>
                      <td className="px-6 py-4 whitespace-nowrap" key={image.id}>
                        <button 
                          style={{fontFamily: "Muli"}}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                          onClick={() => handleDeleteOnset(image.id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onset;