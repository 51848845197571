import React, { useState, useEffect } from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import {Helmet} from "react-helmet";
import image from "../assets/ourworks2.jpg"

// import three from '../assets/portraits/3.jpg'
// import four from '../assets/portraits/4.jpg'
// import fifteen from '../assets/humanitarian/15.jpg'
// import slider from '../assets/humanitarian/14.jpg'
// import slider2 from '../assets/humanitarian/11.jpg'


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Blog = () => {

  const [products, setProducts] = useState([]);

	const fetchProducts = async () => {
	    try {
	      const response = await fetch('https://sope-backend.vercel.app/post');
	      console.log('Response status:', response.status)
	      if (response.ok) {
	        const data = await response.json();
	        setProducts(data);
	        console.log(data);
	      } else {
	        console.error('Error fetching products');
	      }
	    } catch (error) {
	      console.error('Error fetching products:', error);
	    }
	};

	useEffect(() => {
	    fetchProducts();
	}, []);

	return (
		<>
			<Helmet>
		        <title>Sope Adelaja | Photographer - Film Director</title>
		        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
		    </Helmet>
			{/*<div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] 
				xl:px-20 xl:py-4 md:px-4 md:py-4 bg-[#252525]">
				<Swiper
			        spaceBetween={30}
			        centeredSlides={true}
			        autoplay={{
			          delay: 2500,
			          disableOnInteraction: false,
			        }}
			        pagination={{
			          clickable: true,
			        }}
			        // navigation={true}
			        modules={[Autoplay, Pagination]}
			        className="mySwiper"
			    >
			    	<SwiperSlide>
			          <div className="relative w-full h-full">
				        <img src={slider} alt="" className="w-full h-full object-cover" />
				        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

				        <div className="absolute bottom-5 right-5 w-[400px]">
				          <p className="text-[#b7a78b] text-2xl text-left font-semibold uppercase tracking-[.15em]">jos crisis</p>
				          <p className="text-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
				          ut labore et dolore magna aliqua.</p>
				        </div>
				      </div>
				   	</SwiperSlide>
			        <SwiperSlide>
				      <div className="relative w-full h-full">
				        <img src={slider2} alt="" className="w-full h-full object-cover" />

				        <div className="absolute inset-0 bg-black bg-opacity-50"></div>

				        <div className="absolute bottom-5 right-5 w-[400px]">
				          <p className="text-[#b7a78b] text-2xl text-left font-semibold uppercase tracking-[.15em]">kano nigeria</p>
				          <p className="text-white text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
				          ut labore et dolore magna aliqua.</p>
				        </div>
				      </div>
				    </SwiperSlide>
			    </Swiper>
		    </div>

			
			<section className="pb-24 bg-[#252525]">
		        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
		          <div className="border-b border-[#b7a78b] py-4 mb-6">
						<h2 className="uppercase text-[#b7a78b] text-2xl font-semibold tracking-[.15em]" 
							style={{fontFamily: "Hanken Grotesk"}}>sope adelaja's blog</h2>
				  </div>
		          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-8 lg:gap-y-0 gap-x-5 mb-14 justify-center">
		            <div className="group cursor-pointer w-full p-5 transition-all duration-300 transform hover:scale-105 hover:bg-[#252525] hover:shadow-lg">
		            	<a href="#">
			            	<div className="flex items-center mb-6 overflow-hidden h-64">
			                  <img 
			                  	src={three} 
			                  	alt="Harsh image" 
			                  	className="w-full object-cover transition-transform duration-500 group-hover:scale-110"
			                  />
			            	</div>
		    	        	<div className="block text-center">
		                		<h4 className="text-[#d5c9b4] text-[21px] leading-8 mb-2 capitalizegroup-hover:text-white transition-colors duration-300">
		                			Fintech 101: 
		                		</h4>
		                		<div className="flex items-center justify-center font-medium">
		                    		<h6 className="text-[13px] text-gray-500 group-hover:text-gray-400 transition-colors duration-300">By Harsh C.</h6>
		                    		<span className="px-2 text-white">--</span>
		                    		<span className="text-[13px] text-white capitalize">september 2, 2022</span>
		                		</div>
		            		</div>
		            	</a>
		            </div>
		            <div className="group cursor-pointer w-full p-5 transition-all duration-300 transform hover:scale-105 hover:bg-[#252525] hover:shadow-lg">
					  <a href="#">
					    <div className="flex items-center mb-6 overflow-hidden h-64">
					      <img
					        src={four}
					        alt="John image"
					        className="w-full object-cover transition-transform duration-500 group-hover:scale-110"
					      />
					    </div>
					    <div className="block text-center">
					      <h4 className="text-[#d5c9b4] text-[21px] leading-8 mb-2 capitalize group-hover:text-white transition-colors duration-300">
					        From Classroom to Cyberspace:
					      </h4>
					      <div className="flex items-center justify-center font-medium">
					        <h6 className="text-[13px] text-gray-500 group-hover:text-gray-400 transition-colors duration-300">
					          By John D.
					        </h6>
					        <span className="px-2 text-white">--</span>
					        <span className="text-[13px] text-white capitalize">
					          january 13, 2021
					        </span>
					      </div>
					    </div>
					  </a>
					</div>

		            <div className="group cursor-pointer w-full p-5 transition-all duration-300 transform hover:scale-105 hover:bg-[#252525] hover:shadow-lg">
		            	<a href="#">
		            		<div className="flex items-center mb-6 overflow-hidden h-64">
		                		<img 
		                			src={fifteen}
		                			alt="Alexa image" 
		                			className="w-full object-cover transition-transform duration-500 group-hover:scale-1" />
		            		</div>
		            		<div className="block text-center">
		                		<h4 className="text-[#d5c9b4] text-[21px] leading-8 mb-2 capitalize group-hover:text-white transition-colors duration-300">
		                			Fintech Solutions
		                		</h4>
		                		<div className="flex items-center justify-center font-medium">
		                    		<h6 className="text-[13px] text-gray-500 group-hover:text-gray-400 transition-colors duration-300">By Alexa H.</h6>
		                    		<span className="px-2 text-white">--</span>
		                    		<span className="text-[13px] text-white capitalize">march 24, 2024</span>
		                		</div>
		            		</div>
		            	</a>
		            </div>
		          </div>
		          <a href="javascript:;" 
		          	className="cursor-pointer border border-[#b7a78b]
		          		shadow-sm py-3.5 px-7 w-52 flex justify-center items-center 
		          		text-white font-semibold mx-auto transition-all duration-300 
		          		hover:bg-[#b7a78b] hover:text-black">View All</a>
		        </div>
		    </section>*/}

		</>
	)
}

export default Blog