import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

const PostBlog = () => {
	const [postInfo, setPostInfo] = useState(null)
	const {id} = useParams()

	// const URL = import.meta.env.VITE_BASE_URL;

	useEffect(() => {
		fetch(`https://sope-backend.vercel.app/post/${id}`)
		.then(response => {
			response.json().then(postInfo => {
				setPostInfo(postInfo)
			})
		})
	}, [])

	if(!postInfo) return ''

	return (
		<>
			<div>
				<h1 className="text-center py-10 text-4xl font-bold capitalize">{postInfo.title}</h1>
				<p className="text-center mb-5">
					<Link to={`/admin/edit/${postInfo._id}`}>Edit</Link>
				</p>
				<img src={`${URL}/${postInfo.cover}`} alt="" className="" />
			</div>
			<div>
				<div dangerouslySetInnerHTML={{__html:postInfo.content}} />
			</div>
		</>
	)
}

export default PostBlog