import React, { useState, useEffect } from "react";

import image1 from "../assets/homepage/1.jpg";
import image2 from "../assets/homepage/2.jpg";
import image4 from "../assets/homepage/4.jpg";
import image5 from "../assets/homepage/5.jpg";
import image6 from "../assets/homepage/6.jpg";
import image7 from "../assets/homepage/7.jpg";
import image8 from "../assets/homepage/8.jpg";
import image9 from "../assets/homepage/9.jpg";
import image10 from "../assets/homepage/10.jpg";
import image11 from "../assets/homepage/11.jpg";
import image12 from "../assets/homepage/12.jpg";
import image14 from "../assets/homepage/14.jpg";
import image16 from "../assets/homepage/16.jpg";
import image17 from "../assets/homepage/17.jpg";
import image18 from "../assets/homepage/18.jpg";
import image19 from "../assets/homepage/19.jpg";
import image21 from "../assets/homepage/21.jpg";
import image22 from "../assets/homepage/22.jpg";
import image23 from "../assets/homepage/23.jpg";
import image24 from "../assets/homepage/24.jpg";
import image25 from "../assets/homepage/25.jpg";
import image26 from "../assets/homepage/26.jpg";
import image27 from "../assets/homepage/27.jpg";
import image28 from "../assets/homepage/28.jpg";
import arrowLeft from '../assets/arrow-left.png'
import arrowRight from '../assets/arrow-right.png'
import close from '../assets/close.png'

const images = [
  image1, image2, image4, image5, image6, image7, image8, image9,
  image10, image11, image12, image14, image16, image17, image18,
  image19, image21, image22, image23, image24, image25, image26,
  image27, image28
];

const Home = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const [columns, setColumns] = useState(4);
  const [imageColumns, setImageColumns] = useState([]);

  // Responsive column adjustment
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 640) setColumns(1);
      else if (screenWidth < 768) setColumns(2);
      else if (screenWidth < 1024) setColumns(3);
      else setColumns(4);
    };

    // Initial setup and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Distribute images across columns
  useEffect(() => {
    if (images.length === 0) return;

    // Create empty columns
    const newColumns = Array.from({ length: columns }, () => []);

    // Distribute images across columns
    images.forEach((image, index) => {
      const columnIndex = index % columns;
      newColumns[columnIndex].push({ src: image, index });
    });

    setImageColumns(newColumns);
  }, [columns]);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setCurrentImageIndex(0);
  };

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <>
      <div className="bg-[#252525] min-h-screen py-8 xl:px-[150px] lg:px-[150px] px-[30px]">
        <div className="flex gap-4">
          {imageColumns.map((column, colIndex) => (
            <div key={colIndex} className="flex-1 flex flex-col gap-4">
              {column.map((image, imgIndex) => (
                <div key={imgIndex} className="w-full cursor-pointer" onClick={() => openLightbox(image.index)}>
                  <img
                    src={image.src}
                    alt={`Gallery ${image.index + 1}`}
                    className="w-full object-cover shadow-md hover:shadow-xl transition-transform duration-300 hover:scale-105"
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* Lightbox */}
      {lightboxOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative flex items-center justify-center w-full">
            {/* Previous Button */}
            <button
              onClick={showPrevImage}
              className="absolute left-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
            >
              <img width="24" height="24" src={arrowLeft}/>
            </button>

            {/* Image */}
            <img
              src={images[currentImageIndex]}
              alt={`Lightbox ${currentImageIndex + 1}`}
              className="max-w-full max-h-screen"
            />

            {/* Next Button */}
            <button
              onClick={showNextImage}
              className="absolute right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
            >
              <img width="24" height="24" src={arrowRight}/>
            </button>

            {/* Close Button */}
            <button
              onClick={closeLightbox}
              className="absolute top-4 right-4 text-white text-3xl w-10 h-10 flex items-center justify-center"
            >
              <img width="24" height="24" src={close}/>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
