// src/components/Preloader.js
import React from 'react';

const Pre = () => (
  <div className="preloader">
    <div className="preloader-content">
      <div className="text-container">
        <div className="preloader-text">Sope</div>
        <div className="moving-text">
          <div className="text">Adelaja</div>
          <div className="text">Photographer</div>
          <div className="text">Filmmaker</div>
        </div>
      </div>
    </div>
  </div>
);

export default Pre;
