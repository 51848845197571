import React from "react";

function Sidebar() {
  return (
    <>
      <div
        className="hiiden-sidebar-wrap outsb"
        tabindex="0"
        style={{ overflow: "hidden", outline: "none" }}
      >
        {/* <!-- sb-widget-wrap--> */}
        <div className="sb-widget-wrap fl-wrap">
          <h3>SUBSCRIBE TO OUR NEWSLETTER</h3>
          <div className="sb-widget  fl-wrap">
            <p>
              Sign up for our newsletter so you can be the first to find out the
              latest news and tips about my works.
            </p>
            <div className="subcribe-form fl-wrap">
              <form id="subscribe" novalidate="true">
                <input
                  className="enteremail"
                  name="EMAIL"
                  id="subscribe-email"
                  placeholder="Your Email"
                  spellcheck="false"
                  type="text"
                />
                <button
                  type="submit"
                  id="subscribe-button"
                  className="subscribe-button"
                >
                  Submit
                </button>
                <label
                  for="subscribe-email"
                  className="subscribe-message"
                ></label>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- sb-widget-wrap end-->   */}
        {/* <!-- sb-widget-wrap--> */}
        <div className="sb-widget-wrap fl-wrap">
          <h3>Follow us</h3>
          <div className="sb-widget    fl-wrap">
            <div className="sidebar-social fl-wrap">
              <ul>
                <li>
                  <a href="#" target="_blank">
                    <i className="icon-facebook2"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/joeladu_/" target="_blank">
                    <i className="icon-instagram2"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="icon-twitter2"></i>
                  </a>
                </li>
                <li>
                  <a href="www.linkedin.com/in/joeladu" target="_blank">
                    <i className="icon-linkedin2"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="sb-widget-wrap fl-wrap">
          <div className="sb-widget  fl-wrap">
            <p>
              copyright Joel Adu 2021
              <br /> Developed by{" "}
              <a href="instagram.com/oprime.ng" target="_blank">
                Oprime Technologies
              </a>
            </p>
          </div>
        </div>
        {/* <!-- sb-widget-wrap end-->                                    */}
        {/* <!-- sb-widget-wrap--> */}

        {/* <!-- sidebar end -->    */}
      </div>
    </>
  );
}

export default Sidebar;
