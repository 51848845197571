import React from "react"
import { Linkedin } from 'lucide-react';
import { Instagram } from 'lucide-react';
import { ArrowUp } from 'lucide-react';
// import behance from '../assets/behance.png'

const Footer = () => {
	const scrollToTop = () => {
	  window.scrollTo({
	    top: 0,
	    behavior: 'smooth' // This makes the scrolling smooth
	  });
	};

	return (
		<>
			<footer className="py-6 bg-[#252525]">
		      <div className="flex flex-col-reverse xl:justify-evenly lg:justify-evenly justify-between sm:flex-row sm:grid sm:grid-cols-3 gap-4 text-center sm:text-left">
		        
		        <div className="flex justify-center items-center order-2 xl:order-none lg:order-none md:order-none sm:order-none space-x-4">
		          <a href="https://linkein.com/in/sope-adelaja" target="_blank" rel="noreferrer" className="hover:text-gray-600">
		          	<Linkedin color="white" className="w-[16px] h-[16px]"/>
		          </a>
		          <a href="https://instagram.com/sopeadelaja" target="_blank" rel="noreferrer" className="hover:text-gray-600">
		          	<Instagram color="white" className="w-[16px] h-[16px]" />
		          </a>
		          {/*<a href="https://behance.com"><img width="16" height="16" src={behance} alt="behance"/></a>*/}
		        </div>

		        <div className="flex justify-center items-center order-1 xl:order-none lg:order-none md:order-none sm:order-none ">
		          <p className="capitalize tracking-[0.2em] text-gray-600 text-center text-white text-[12px]">© sope adelaja 2024. all rights reserved</p>
		        </div>

		        <div className="flex justify-center items-center order-3 xl:order-none lg:order-none md:order-none sm:order-none ">
		          <p className="p-2 rounded cursor-pointer hover:border border-black-500"
		          	onClick={scrollToTop}>
		            <ArrowUp color="white" />
		          </p>
		        </div>
		      </div>
		    </footer>


		</>
	)
}

export default Footer


    
