import React, { useState } from "react"
import {Helmet} from "react-helmet";
import image from "../assets/contact.jpg"

const Contact  = () => {
	const [formData, setFormData] = useState({
	    fullName: '',
	    email: '',
	    message: '',
	});

	const contactForm = async (e) => {
		e.preventDefault()
		try {
			// const response = await fetch('http://localhost:4000/contact', {
			const response = await fetch('https://sope-backend.vercel.app/contact', {
		        method: 'POST',
		        headers: {
		          'Content-Type': 'application/json',
        		},
        		body: JSON.stringify(formData),
    		});
			if (response.ok) {
	        console.log('Form submitted successfully');
	        alert('Form submitted successfully')
	        // Reset the form data after successful submission
	        setFormData({
	          fullName: '',
	          email: '',
	          message: '',
	        });
	      } else {
	        console.error('Form submission failed');
	      }
	    } catch (error) {
	      console.error('Error submitting form:', error);
	    }
	}

	const handleInputChange = (e) => {
	    const { name, value } = e.target;
	    setFormData((prevData) => ({
	      ...prevData,
	      [name]: value,
	    }));
	};

	return (
		<>
			<Helmet>
		        <title>Sope Adelaja | Photographer - Film Director</title>
		        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
		    </Helmet>
			<div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] 
				xl:px-20 xl:py-4 px-4 py-4 bg-[#252525]">
				<img className="object-cover h-[100%] w-[100%]" src={image} alt="cover" />
			</div>
			<div className="bg-[#252525]">
				<div className="mx-auto px-4 py-4"> 
					<div className="grid grid-cols-1 gap-8 pt-10 pb-20">
						<div className="px-0 xl:px-[15em] lg:px-10 
							space-y-8 mt-[30px] xl:mt-0 lg:mt-0 md:mt-[30px]
							order-2 xl:order-1 lg:order-1 md:order-2">
							<div className="border-b border-[#b7a78b] py-4">
								<p className="text-[#b7a78b] uppercase font-semibold md:text-3xl text-xl tracking-[0.05em]" 
								style={{fontFamily: "Hanken Grotesk"}}>ways to get in touch</p>
							</div>
							<div className="space-y-2">
								{/*<p className="text-white capitalize" style={{fontFamily: "Hanken Grotesk"}}>
									<span className="text-[#b7a78b]">facebook:</span> sope adelaja</p>*/}
								<p className="text-white" style={{fontFamily: "Hanken Grotesk"}}>
									<span className="text-white">email:</span> 
									<a href="mailto:hello@sopeadelaja.com" className="text-[#b7a78b] hover:underline decoration-current"> hello@sopeadelaja.com</a>
								</p>
								<p className="text-white capitalize" style={{fontFamily: "Hanken Grotesk"}}>
									<span className="text-white capitalize">linkedin:</span>
									<a href="https://linkedin.com/in/sope-adelaja/" className="text-[#b7a78b] hover:underline decoration-current" target="_blank" rel="noreferrer"> sope adelaja</a>
								</p>
								<p className="text-white capitalize" style={{fontFamily: "Hanken Grotesk"}}>
									<span>Instagram:</span> 
									<a href="https://instagram.com/sopeadelaja" target="_blank" rel="noreferrer" className="text-[#b7a78b] hover:underline decoration-current"> @sopeadelaja</a>
								</p>
							</div>
						</div>
					</div>

				</div>

				<div className="container mx-auto px-4 pb-12 pt-2">
					<div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pt-10 pb-20">
						{/* Left Section */}
						<div className="px-0 xl:px-10 lg:px-10 flex flex-col justify-center text-center 
							xl:text-right lg:text-right md:text-center space-y-8 mt-[60px] xl:mt-0 lg:mt-0 md:mt-[30px]
							order-2 xl:order-1 lg:order-1 md:order-2">
						    <div>
						      <p className="text-xl text-[#b7a78b] font-semibold uppercase tracking-[0.1em]"
						      	style={{fontFamily: "Hanken Grotesk"}}>Inquiries</p>
						      <p className="text-white"><a href="mailto:hello@sopeadeaja.com">hello@sopeadelaja.com</a></p>
						    </div>
						    <div>
						      <p className="text-xl text-[#b7a78b] font-semibold uppercase tracking-[0.1em]"
						      	style={{fontFamily: "Hanken Grotesk"}}>Follow</p>
						      <p className="text-white">
						      <a href="https://instagram.com/sopeadelaja" target="_blank" rel="noreferrer">Instagram</a> / <a href="https://x.com">X</a></p>
						    </div>
						</div>

						{/* Right Section - Form */}
						<div className="order-1 xl:order-2 lg:order-2 md:order-1">
						    <p className="text-lg text-white font-medium text-4xl mb-6">For any questions or inquiries, please use the form below:</p>

						    <form className="space-y-4" onSubmit={contactForm}>
						      {/* Name Input */}
						      <input
						        type="text"
						        placeholder="NAME"
						        name="fullName"
						        value={formData.fullName}
        						onChange={handleInputChange}
        						required
						        className="text-[12px] bg-[#b7a78b] w-full p-3 border border-[#a99494] capitalize text-white
						        	focus:outline-none focus:ring-2 focus:ring-black placeholder:text-white"
						      />
						      
						      {/* Email Input */}
						      <input
						        type="email"
						        placeholder="EMAIL"
						        name="email"
								value={formData.email}
        						onChange={handleInputChange}
						        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}"
								required
						        className="text-[12px] bg-[#b7a78b] w-full p-3 border border-[#a99494] text-white
						        	focus:outline-none focus:ring-2 focus:ring-black placeholder:text-white"
						      />
						      
						      {/* Message Input */}
						      <textarea
						        placeholder="MESSAGE"
						        rows="5"
						        name="message"
						        value={formData.message}
        						onChange={handleInputChange}
        						required
						        className="text-[12px] bg-[#b7a78b] w-full p-3 border border-[#a99494] capitalize text-white
						        	focus:outline-none focus:ring-2 focus:ring-black placeholder:text-white"
						      ></textarea>
						      
						      {/* Submit Button */}
						      <button className="
						        w-[200px] text-white bg-transparent transition border border-[#b7a78b] text-[12px] font-medium py-7
						        hover:bg-[#b7a78b] hover:text-white transition uppercase tracking-[0.2em]">
						        Send Message
						      </button>
						    </form>
						  </div>
					</div>
				</div>
			</div>

		</>
	)
}

export default Contact