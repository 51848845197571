import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckoutModal from './CheckoutModal';
import { Navigate } from 'react-router-dom';
import {
  selectCartItems,
  selectCartState,
  selectTotalAmount,
  selectTotalQTY,
  setClearCartItems,
  setCloseCart,
  setGetTotals
} from "../CartSlice.js";
import CartCount from "./cart/CartCount";
import CartEmpty from "./cart/CartEmpty";
import CartItem from "./cart/CartItem";




const Cart = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [cart, setCart] = useState([]);

  const publicKey = "pk_test_7114661f512e87772aefeab61d6811334b084c85"

  const dispatch = useDispatch();
  const ifCartState = useSelector(selectCartState);
  const cartItems = useSelector(selectCartItems);
  const totalAmount = useSelector(selectTotalAmount);
  const totalQTY = useSelector(selectTotalQTY);

  const paystackAmount = totalAmount * 100

  useEffect(() => {
    dispatch(setGetTotals());
  }, [cartItems, dispatch]);

  const onCartToggle = () => {
    dispatch(setCloseCart({ cartState: false }));
  };

  const onClearCartItems = () => {
    dispatch(setClearCartItems());
  };

  // const naira = '\u20A6';

  const openModal = () => {
    setIsOpen(true);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [paymentReference, setPaymentReference] = useState(null);

  const [formData, setFormData] = useState({ 
      name: "",
      address: "",
      email: "",
      phone: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("userData", JSON.stringify(formData));
    
    closeModal();
  };
  
    const [cartData, setCartData] = useState({
      userData: JSON.parse(localStorage.getItem("userData")),
      cartInfo: JSON.parse(localStorage.getItem("cart")),
    })


  // PAYSTACK INTEGRATION
    const handleUpload = async (e) => {
      e.preventDefault()

      if (!formData.name || !formData.address || !formData.email || !formData.phone) {
        alert('Please fill in all required fields.');
        return;
      }

      try {
        const response = await fetch('http://localhost:4000/success', {
          method: 'POST',
          heaaders: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const responseData = await response.json();
          console.log("Response from backend:", responseData);
          alert('Image uploaded successfully');
          // Refresh the page after successful submission
          window.location.reload();
        } else {
          console.error('Error uploading image:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    };

    const handlePaystackCloseAction = () => {
      alert("Wait! Don't leave ")
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }

    const componentProps = {
      reference: (new Date()).getTime().toString(),
        email: formData.email,
        amount: paystackAmount,
        metadata: {
          name: formData.name,
          phone: formData.phone,
        },
        publicKey,
        text: "Pay Now",
        // onSuccess: (reference) => {
        //   handlePaystackSuccessAction(reference);
        //   alert("order successful")
        // },
        onSuccess: () => {
          handleUpload()
        },
        onClose: handlePaystackCloseAction,
    }


  return (
    <>
      <div
        className={`fixed top-0 left-0 right-0 bottom-0 blur-effect-theme duration-500 w-full h-screen opacity-100 z-[250] ${
          ifCartState
            ? "opacity-100 visible translate-x-0"
            : "opacity-0 invisible translate-x-full"
        }`}
      >
        <div
          className={`blur-effect-theme bg-[#cecece] shadow-lg duration-500 h-screen max-w-[350px] w-full absolute right-0 ${
            ifCartState
              ? "opacity-100 visible translate-x-0"
              : "opacity-0 invisible translate-x-full"
          }`}
        >
          <CartCount
            totalQTY={totalQTY}
            onCartToggle={onCartToggle}
            onClearCartItems={onClearCartItems}
          />
          {cartItems?.length === 0 ? (
            <CartEmpty onCartToggle={onCartToggle} />
          ) : (
            <div>
              <div className="flex items-start justify-start flex-col gap-y-7 lg:gap-y-5 overflow-y-scroll h-[81vh] scroll-smooth scroll-hidden py-3">
                {cartItems?.map((item, i) => (
                  <CartItem key={i} item={item} />
                ))}
              </div>

              

              {/*checkout*/}

            </div>
          )}
            <CheckoutModal
              isOpen={modalIsOpen}
              closeModal={closeModal}
              totalAmount={totalAmount}
              onClearCartItems={onClearCartItems}
            />
        </div>
      </div>
    </>
  );
};

export default Cart;
