import React,{ useState } from "react";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { 
  setDecreaseItemQTY,
  setIncreaseItemQTY,
  setRemoveItemFromCart,
  setClearCartItems,
  setCloseCart,
  selectTotalAmount
} from "../../CartSlice.js";
import CheckoutModal from '../CheckoutModal';

// function truncateText(text, maxLength) {
//   const words = text.split(' ');
//   if (words.length > maxLength) {
//     return words.slice(0, maxLength).join(' ') + '...';
//   }
//   return text;
// }


const CartItem = ({ item: { id, title, text, img, color, shadow, price, cartQuantity, currency }, cartItems }) => {
  let subtitle;
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);

  const totalAmount = useSelector(selectTotalAmount);
  
  const onRemoveItem = () => {
    dispatch(setRemoveItemFromCart({ id }));
  };

  const onIncreaseItemQTY = () => {
    dispatch(setIncreaseItemQTY({ id }));
  };

  const onDecreaseItemQTY = () => {
    dispatch(setDecreaseItemQTY({ id }));
  };

  const onClearCartItems = () => {
    dispatch(setClearCartItems());
  };


  // const truncatedText = truncateText(text, 20);

  
  const openModal = () => {
    setIsOpen(true);
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }




  // new methodology for currency change



  return (
    <>
      <div className="flex items-center justify-between w-full px-1">
        <div className="flex items-center gap-5">
          <div className={`bg-gradient-to-b ${color} ${shadow} relative rounded p-3 hover:scale-105 transition-all duration-75 ease-in-out grid items-center`}>
            <img src={img} height="80" width="80" alt={`img/cart-item/${id}`} className="w-20 h-auto object-fill lg:w-20" />
            {/*<div className='absolute right-1 top-1 blur-theme-effect bg-white/80 text-black text-xs px-1 rounded'>{naira}{price}</div>*/}
          </div>
          <div className="grid items-center gap-4">
            <div className="grid items-center leading-none">
              <h1 className="font-medium text-lg text-[#333] capitalize lg:text-xl xl:text-xl">{title}</h1>
              {/*<p className="text-sm text-white lg:text-xs">{truncatedText}</p>*/}
            </div>
            {/*PRODUCT QUANTITY*/}
            <div className="flex items-center justify-around w-full">
              <button 
                type="button" 
                onClick={onDecreaseItemQTY} 
                className="rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90
                  border border-[#b7a78b]">
                <MinusIcon className="w-5 h-5 lg:w-4 lg:h-4 text-[#333] stroke-[2]" />
              </button>
              <div className="bg-theme-cart rounded text-[#333] font-medium 
                lg:text-xs w-7 h-6 lg:h-5 lg:w-6 flex items-center justify-center">{cartQuantity}</div>
              <button 
                type="button" 
                onClick={onIncreaseItemQTY} 
                className="rounded w-6 h-6 lg:w-5 lg:h-5 flex items-center justify-center active:scale-90
                  border border-[#b7a78b]">
                <PlusIcon className="w-5 h-5 lg:w-4 lg:h-4 text-[#333] stroke-[2]" />
              </button>
            </div>
            {/*PRODUCT QUANTITY END*/}
          </div>
        </div>
        <div className="grid items-center gap-5">
          <div className="grid items-center justify-center">
            {/*<h1 className="text-lg lg:text-base text-[#333] font-medium">{naira}{Number(price * cartQuantity).toLocaleString()}</h1>*/}
            <h1 className="text-lg lg:text-base text-[#333] font-medium">
              {currency === 'NGN' ? '₦' : currency === 'USD' ? '$' : currency === 'EUR' ? '€' : '£'}
              {
                currency === 'NGN' ? (
                  (Number(price * cartQuantity).toLocaleString())
                ) : currency === 'USD' ? (
                  (price * cartQuantity / 1677.9).toFixed(2)
                ) : currency === 'EUR' ? (
                  (price * cartQuantity / 1782.5).toFixed(2)
                ) : (
                  (price * cartQuantity / 2132.2).toFixed(2)
                )
              } 
            </h1>
          </div>
          <div className="grid items-center justify-center">
            <button type="button" className="bg-theme-cart rounded p-1 lg:p-0.5 grid items-center justify-items-center cursor-pointer" onClick={onRemoveItem}>
              <TrashIcon className="w-5 h-5 text-white" />
            </button>
          </div>
        </div>
      </div>


      <div className="fixed bottom-0 bg-[#cecece] w-full px-5 py-2 grid items-center border-t border-[#b7a78b]">
        <div className="flex items-center justify-between">
          <h1 className="text-base text-[#333] font-semibold uppercase">
            SubTotal
          </h1>
          <h1 className="text-base text-[#333] font-semibold rounded bg-theme-cart px-1 py-0.5">
            {currency === 'NGN' ? '₦' : currency === 'USD' ? '$' : currency === 'EUR' ? '€' : '£'}
            {
              currency === 'NGN' ? (
                (totalAmount)
              ) : currency === 'USD' ? (
                (totalAmount / 1677.9).toFixed(2)
              ) : currency === 'EUR' ? (
                (totalAmount / 1782.5).toFixed(2)
              ) : (
                (totalAmount / 2132.2).toFixed(2)
              )
            } 
          </h1>
        </div>
        <div className="grid items-center gap-2">
          <p className="text-sm text-[#333] font-medium text-center">
            Taxes and Shipping Will Be Calculated At Checkout
          </p>
          <button
            type="button"
            className="bg-[#b7a78b] hover:bg-[#817259] transition text-white px-8 py-4 text-lg "
            onClick={openModal}
          >
            Check Out
          </button>
        </div>
      </div>
      <CheckoutModal
        isOpen={modalIsOpen}
        closeModal={closeModal}
        totalAmount={totalAmount}
        onClearCartItems={onClearCartItems}
      />
    </>
  );
};

export default CartItem;