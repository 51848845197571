import React from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Template/Navbar";
import Sidebar from "../Template/Sidebar";
import ShopWrapper from "./ShopWrapper";
import image from "../assets/ourworks2.jpg"

function Shop() {
  return (
    <>
      <Helmet>
        <title>Sope Adelaja | Print Store</title>
        <meta name="description" content="photographer, photography, portrait, commercial photographer" />
      </Helmet>
      <div id="main" style={{ opacity: "1" }} className="bg-[#252525]">
        <div className="xl:h-[300px] lg:h-[300px] md:h-[300px] h-[300px] md:px-20 px-4 py-4 bg-[#252525]">
          <img className="object-cover h-[100%] w-[100%]" src={image} alt="cover" />
        </div>
        <ShopWrapper />
        <div className="sb-overlay"></div>
      </div>
    </>
  );
}

export default Shop;
