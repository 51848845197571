import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setAddItemToCart, 
  setOpenCart, 
  selectTotalQTY, 
} from '../CartSlice';
import shoppingBag from '../assets/shopping-bag.png';


const ProductDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [price, setPrice] = useState(null);
  const dispatch = useDispatch();
  const totalQTY = useSelector(selectTotalQTY);
  const [currency, setLocalCurrency] = useState('NGN');
  const [selectedProductDetails, setSelectedProductDetails] = useState({});
  const [rates, setRates] = useState({});
  const [cart, setCart] = useState([]);


  const handleSizeChange = (e) => {
    const selected = product.sizes.find(size => size.size === e.target.value);
    setSelectedSize(selected.size);
    setPrice(selected.price);
    setSelectedProductDetails({ ...selectedProductDetails, [product.id]: { price: selected.price } });
  };

  


  const fetchExchangeRates = async () => {
    try {
      const response = await fetch('https://api.exchangerate-api.com/v4/latest/NGN');
      const data = await response.json();
      setRates(data.rates);  // Store exchange rates
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  };

  useEffect(() => {
    fetchExchangeRates();
  }, []);


  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(`http://localhost:4000/product/${id}`);
        const data = await response.json();
        setProduct(data);
        setSelectedSize(data.sizes[0]?.size);  // Set initial size
        setPrice(data.sizes[0]?.price); 
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    };
    fetchProductDetails();
  }, [id]);

  
  const onAddToCart = () => {
    const quantity = getCartQuantity(product.id) || 1; // Default to 1 if no quantity exists
    const convertedPrice = convertPrice(price, 'NGN', currency, rates);

    if (product) {
      const cartItem = {
        id: product.id,
        title: product.name,
        text: product.summary,
        img: product.cover,
        price: price, // Store the numeric value
        cartQuantity: quantity,
        selectedSize: selectedSize,
        currency: currency, // Include selected currency
      };

      dispatch(setAddItemToCart(cartItem));

      // Open the cart after adding the product
      dispatch(setOpenCart({ cartState: true }));
    }
  };



  const updateCart = (product, change) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item.id === product.id);

      if (existingItem) {
        const updatedCart = prevCart.map(item =>
          item.id === product.id
            ? { ...item, quantity: Math.max(0, item.quantity + change) }
            : item
        ).filter(item => item.quantity > 0);
        return updatedCart;
      } else if (change > 0) {
        return [...prevCart, { ...product, quantity: change }];
      }

      return prevCart;
    });
  };

  const getCartQuantity = (productId) => {
    const item = cart.find(item => item.id === productId);
    return item ? item.quantity : 0;
  };

  if (!product) {
    return <p>Loading...</p>;
  }

  // const naira = '\u20A6';


  const onCartToggle = () => {
    dispatch(setOpenCart({ cartState: true }));
  };



  // Define conversion rates
  const conversionRates = {
    NGN: 1,
    USD: 1677.9,
    EUR: 1782.5,
    GBP: 2132.2
  };

  // Helper function to convert prices between currencies
  const convertPrice = (amount, fromCurrency, toCurrency) => {
    // Convert to NGN first (base currency)
    const amountInNGN = fromCurrency === 'NGN' 
      ? amount.toFixed(2) 
      : amount * conversionRates[fromCurrency];
    
    // Then convert to target currency
    return toCurrency === 'NGN' 
      ? amountInNGN 
      : amountInNGN / conversionRates[toCurrency];
  };



  return (
    <div className="p-10 bg-[#252525] h-screen xl:mb-0 mb-20">

      <div className="flex items-center justify-between border-b border-[#b7a78b] py-4 my-2 xl:mx-[250px] lg:mx-[150px] md:mx-[20px] mx-[4px] gap-2">
        {/* Currency Selector */}
        <div className="flex items-center">
          <label className="mr-2 text-white">Currency:</label>
          <select
            value={currency}
            onChange={(e) => setLocalCurrency(e.target.value)}
            className="bg-[#373333] border border-[#373333] text-white p-2 cursor-pointer"
          >
            <option value="NGN">Naira (₦)</option>
            <option value="USD">USD ($)</option>
            <option value="EUR">Euro (€)</option>
            <option value="GBP">Pound (£)</option>
          </select>
        </div>

        <div className="flex items-center justify-end cursor-pointer" onClick={onCartToggle}>
          <img src={shoppingBag} width="27" height="27" alt="shopping bag" />
          <p className="capitalize text-white text-xl pl-2">cart({totalQTY})</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row xl:px-[250px] lg:px-[200px] md:px-[150px] px-[10px] mx-auto mt-20">
        <div className="w-full lg:w-1/2 h-[500px]">
          <img src={product.cover} alt={product.name} className="w-full h-full object-cover" />
        </div>
        <div className="lg:w-1/2 md:px-10 px-4 md:mt-10 md:mt-0 mt-4">
          <p className="xl:text-3xl text-2xl text-white mb-4 uppercase">{product.name}</p>
          <p className="text-3xl font-semibold text-white mb-4">
            {currency === 'NGN' ? '\u20A6' : currency === 'USD' ? '$' : currency === 'EUR' ? '€' : '£'}
            {
              currency === 'NGN' ? (
                Number(price).toLocaleString()
              ) : currency === 'USD' ? (
                (price / 1677.9).toFixed(2)
              ) : currency === 'EUR' ? (
                (price / 1782.5).toFixed(2)
              ) : (
                (price / 2132.2).toFixed(2)
              )
            }

          </p>


          <div className="mb-4">
            <label className="text-white capitalize mb-2 block"> Size</label>
            {product.sizes?.length > 0 ? (
              <select 
                className="bg-transparent border border-[#b7a78b] text-white py-2 px-4 w-full"
                value={selectedSize} 
                onChange={handleSizeChange}
              >
                {product.sizes.map((size, index) => (
                  <option key={index} value={size.size} className="text-black">
                    {size.size}
                  </option>
                ))}
              </select>
            ) : (
              <p className="text-white">No sizes available for this product.</p>
            )}

          </div>

          <p className="text-white capitalize mb-2">quantity</p>
          <div className="flex items-center justify-start gap-2 flex-1 mb-4">
            <button
              onClick={() => updateCart(product, -1)}
              className="bg-transparent border border-[#b7a78b] text-white w-[40px] h-[40px] flex items-center justify-center active:scale-90"
            >
              -
            </button>
            <div className="bg-transparent border border-[#b7a78b] text-white font-medium w-[40px] h-[40px] flex items-center justify-center">
              {getCartQuantity(product.id)}
            </div>
            <button
              onClick={() => updateCart(product, 1)}
              className="bg-transparent border border-[#b7a78b] text-white w-[40px] h-[40px] flex items-center justify-center active:scale-90"
            >
              +
            </button>
          </div>
          <button
            onClick={onAddToCart}
            className="text-white bg-[#b7a78b] px-6 py-3 mt-4 text-base font-medium uppercase tracking-wider hover:bg-[#9e8e73]"
          >
            Add to Cart
          </button>
          <p className="mt-10 text-white">
            2-3 weeks delivery.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;