import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar'
import SubbrandEdit from './SubbrandEdit'

const SubBrandForm = () => {
  const [brandName, setBrandName] = useState('');
  const [selectedBrand, setSelectedBrand] = useState('');
  const [brands, setBrands] = useState([]);
  const [brandList, setBrandList] = useState([])

  // const URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    // Fetch the list of brands when the component mounts
    fetch('https://glintz-serverss.vercel.app/brands')
      .then((response) => response.json())
      .then((data) => setBrands(data))
      .catch((error) => console.error('Error fetching brands:', error));
  }, []);

  const handleBrandSubmit = async () => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/subbrands/${selectedBrand}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: brandName }),
      });

      if (response.ok) {
        const brand = await response.json();
        setBrandName('')
        alert('New Sub portfolio successfully created')
        window.location.reload()
      } else {
        console.error('Error creating brand:', response.statusText);
      }
    } catch (error) {
      console.error('Error creating sub-brand:', error);
    }
  };

  useEffect(() => {
    // Fetch data from the backend
    fetchData();
  }, []);

  const fetchData = async () => {
      try {
        const response = await fetch('https://sope-backend.vercel.app/subbrands'); // Replace with your backend endpoint
        if (response.ok) {
          const data = await response.json();
          setBrandList(data);
        } else {
          console.error('Error fetching data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
  };

  const handleBrandUpdated = (updatedBrand) => {
    const updatedBrands = brandList.map((brand) => (brand._id === updatedBrand._id ? updatedBrand : brand));
    setBrandList(updatedBrands);
  };

  const handleDeleteBrand = async (brandId) => {
    try {
      const response = await fetch(`https://sope-backend.vercel.app/subbrands/${brandId}`, { 
        method: 'DELETE'
      });
      if (response.ok) {
        const updateBrandList = brandList.filter((brand) => brand._id !== brandId)
        setBrandList(updateBrandList)
        alert('sub-brand has been successfully deleted')
      } else {
        console.error('Error deleting brand:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
    }
  };

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="w-[85%]">
          <div className="px-5 py-4 bg-black text-white">
            <p className="capitalize text-lg" style={{fontFamily: "Muli"}}>welcome, admin</p>
          </div>
          <div className="p-20">
            <h2 className="text-4xl font-semibold mb-4">Create Sub-Portfolio</h2>
            <input 
              type="text" 
              style={{fontFamily: "Muli"}}
              placeholder="Sub-Portfolio name" 
              value={brandName} 
              onChange={(e) => setBrandName(e.target.value)} 
              className="border mr-4 pl-4 py-4 w-3/5"
            /><br /><br />
            <select 
              value={selectedBrand} 
              onChange={(e) => setSelectedBrand(e.target.value)}
              className="border w-1/5 py-4 pl-4"
              style={{fontFamily: "Muli"}}>
              <option value="">Select a Brand</option>
              {brands.map((brand) => (
                <option 
                  style={{fontFamily: "Muli"}}
                  key={brand._id} 
                  value={brand._id}>
                  {brand.name}
                </option>
              ))}
            </select><br /><br />
            <button 
              style={{fontFamily: "Muli"}} 
              className="text-white bg-black px-10 py-3 uppercase" 
              onClick={handleBrandSubmit}>
              Create
            </button>
          </div>
          <div className="px-20">
            <h2 className="text-4xl font-semibold mb-4 capitalize">list of sub-portfolios</h2>
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>sub-portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>edit sub portfolio</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{fontFamily: "Muli"}}>delete sub portfolio</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {brandList.length > 0 && brandList.map((brand, index) => (
                    <tr className="hover:bg-gray-100" key={brand._id}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{fontFamily: "Muli"}}>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap capitalize" style={{fontFamily: "Muli"}}>{brand.brandName}</td>
                      <td className="px-6 py-4 whitespace-nowrap capitalize" style={{fontFamily: "Muli"}}>{brand.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <SubbrandEdit subbrandId={brand._id} onBrandUpdated={handleBrandUpdated} />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button 
                          style={{fontFamily: "Muli"}}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase" 
                          onClick={() => handleDeleteBrand(brand._id)}>
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubBrandForm;