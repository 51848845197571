import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar'

const Orders = () => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    try {
      const response = await fetch('http://localhost:4000/orders');
      if (response.ok) {
        const data = await response.json();
        console.log(data.orders)
        setOrders(data.orders);
      } else {
        console.error('Error fetching orders:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const handleDeletePost = async (orderId) => {
    try {
      const response = await fetch(`http://localhost:4000/orders/${orderId}`, { method: 'DELETE' });
      if (response.ok) {
        setOrders(orders.filter((order) => order._id !== orderId));
        alert('Order has been deleted successfully');
      } else {
        console.error('Error deleting order:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  return (
    <div className="flex">
      <Sidebar />
        <div className="p-10">
          <h2 className="text-4xl font-semibold mb-4 capitalize">Orders</h2>
            
          <div>
            <table className="min-w-full border border-gray-300 mt-10">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>s/n</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>name</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>email</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>phone</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>address</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>country</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>reference</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>quantity</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>size</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>price</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>product id</th>
                    <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider" style={{ fontFamily: "Muli" }}>delete</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-300">
                  {orders.map((order, index) => (
                    <tr className="hover:bg-gray-100" key={index}>
                      <td className="px-6 py-4 whitespace-nowrap" style={{ fontFamily: "Muli" }}>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{order.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{order.email}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{order.phone}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{order.address}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{order.country}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{order.reference.reference}</td>
                      {order.cartItem.map((item, idx) => (
                        <>
                          <td className="px-6 py-4 whitespace-nowrap">{item.cartQuantity}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item.selectedSize}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item.price * item.cartQuantity}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{item.id}</td>
                        </>
                      ))}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <img src={order.cover} alt="" className="w-1/5" />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          style={{ fontFamily: "Muli" }}
                          className="bg-red-500 py-2 px-5 text-white font-semibold uppercase"
                          onClick={() => handleDeletePost(order._id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          {/*{orders.map((order, index) => (
            <div key={index}>
              <p>Order {index + 1}</p>
              <p>Name: {order.name}</p>
              <p>Address: {order.address}</p>
              <p>Email: {order.email}</p>
              <p>Phone: {order.phone}</p>
              <p>Country: {order.country}</p>
              <p>Payment Reference: {order.reference.reference}</p>
              <p>
                <ul>
                  {order.cartItem.map((item, idx) => (
                    <li key={idx}>
                      Title: {item.title}, Quantity: {item.cartQuantity}, Price: {item.price}, Product ID: {item.id}
                      
                    </li>
                  ))}
                </ul>
              </p>
            </div>
          ))}*/}
        </div>
    </div>
  );
};

export default Orders;
